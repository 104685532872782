@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import "courcedetail";
@import "cource_card";
@import "home";
@import "loader";
@import "gallery";
@import "booking";
@import "test";
@import "react-confirm-alert";

$text-color-primary: #334750;
$text-color-primary-dark: #0e191d;
$color-pr-orange: #f07924;

body {
  font-family: "Montserrat", sans-serif;
}

.head-centre {
  font-size: 2rem;
  text-align: center;
  margin: 0 0 2em;
}

// Nav Menu
.logo {
  width: 300px;
  margin-left: 10px;
}
.navbar-nav {
  margin-right: 5rem;
}
.navbar {
  background-color: #ffff;
}
.transparent {
  background: none !important;
}

.floatingNav {
  border-radius: 2px;
  box-shadow: 0px 1px 10px #999;
}
.menu {
  position: absolute;
  right: 0;
  button {
    margin-right: 50px;
  }

  z-index: 500;
}
.nav-item {
  margin-right: 20px;
  a {
    text-decoration: none;
    color: $text-color-primary;
    &:hover {
      color: $text-color-primary-dark;
    }
  }
}
.navbar-toggler-icon {
  background-image: url(/static/images/menu-icon.svg) !important;
}
//End Nav

// Footer
footer {
  background-color: #fbfbfb;
}
.branding {
  .logo-footer {
    overflow: hidden;
  }
  .address {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .social-links {
    display: flex;
    justify-content: space-between;
    a {
      color: $text-color-primary;
      &:hover {
        color: $text-color-primary-dark;
      }
    }
  }
}

.quick-links {
  padding: 1rem;
}
.news-letter {
  text-align: center;
  padding: 1rem;
  form {
    margin-top: 2rem;
  }
  input {

    background: #f1f1f1;
    color: $text-color-primary;
    font: inherit;
    border: 0;
    outline: 0;
    padding: 18px 18px;
  }
  button {
    background: linear-gradient(90deg, #f29d25 0%, #ef7824 61.74%);
    border: 0;
    outline: 0;
    color: #ffff;
    font-weight: bold;
    padding: 18px 30px;
    margin-left: 20px;
    &:hover {
      transition: transform 0.25s ease;
      transform: scale(1.02);
    }
  }
  .copyright {
    margin-top: 40px;
    line-height: 2;
    font-size: 0.8em;
    font-weight: 500;
    a {
      text-decoration: none;
    }
  }
}

.footer-menu {
  list-style: none;
  padding-left: 0;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
    a {
      color: $text-color-primary;
      text-decoration: none;
      &:hover {
        color: $text-color-primary-dark;
      }
    }
  }
}
//End Footer

$color-pr-orange: #f07924;
.head-title {
  margin-bottom: 20px;
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
  color: black;
  font-weight: 500;
  span {
    color: $color-pr-orange;
    font-weight: 600;
  }
}

.form-group {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: inline-block;
  width: 100%;
  input {
    height: 1.9rem;
  }
  textarea {
    resize: none;
  }
  .control-label {
    margin-top: -1.9rem;
    text-align: center;
    align-content: center;
    position: relative;
    display: block;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    color: #a7a7a7;
    font-size: 1rem;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
  }
}
.form-group .bar {
  position: relative;
  border-bottom: 0.12rem solid #727272;
  display: block;
}
.form-group .bar::before {
  content: "";
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: $color-pr-orange;
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2;
}
.form-group input,
.form-group textarea {
  display: block;
  background: none;
  text-align: center;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 1rem;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
}
.form-group input:focus,
.form-group input:valid,
.form-group input.form-file,
.form-group input.has-value,
.form-group textarea:focus,
.form-group textarea:valid,
.form-group textarea.form-file,
.form-group textarea.has-value {
  color: #333;
}
.form-group input:focus ~ .control-label,
.form-group input:valid ~ .control-label,
.form-group input.form-file ~ .control-label,
.form-group input.has-value ~ .control-label,
.form-group textarea:focus ~ .control-label,
.form-group textarea:valid ~ .control-label,
.form-group textarea.form-file ~ .control-label,
.form-group textarea.has-value ~ .control-label {
  font-size: 0.8rem;
  color: gray;
  top: -1rem;
  left: 0;
}
.form-group input:focus,
.form-group textarea:focus {
  outline: none;
}
.form-group input:focus ~ .control-label,
.form-group textarea:focus ~ .control-label {
  color: $color-pr-orange;
}
.form-group input:focus ~ .bar::before,
.form-group textarea:focus ~ .bar::before {
  width: 100%;
  left: 0;
}
.has-error .legend.legend,
.has-error.form-group .control-label.control-label {
  color: #d9534f;
}
.has-error.form-group .form-help,
.has-error.form-group .helper,
.has-error.checkbox .form-help,
.has-error.checkbox .helper,
.has-error.radio .form-help,
.has-error.radio .helper,
.has-error.form-radio .form-help,
.has-error.form-radio .helper {
  color: #d9534f;
}
.has-error .bar::before {
  background: #d9534f;
  left: 0;
  width: 100%;
}
.button {
  position: relative;
  background: white;
  border: 1px solid white;
  display: block;
  font-size: 1.1rem;
  border: solid #ff9347;
  margin: 0 auto;
  padding: 0.75rem 3rem;
  cursor: pointer;
  -webkit-transition: background-color 0.28s ease, color 0.28s ease,
    box-shadow 0.28s ease;
  transition: background-color 0.28s ease, color 0.28s ease,
    box-shadow 0.28s ease;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.art{
  background: linear-gradient(90deg, #f29d25 0%, #ef7824 61.74%);
  border: 0;
  outline: 0;
  color: #ffff;
  font-weight: bold;
  padding: 12px 17px;
  margin-left: 37px;
  border-radius: 1px;
}
.button span {
  color: black;
  position: relative;
  z-index: 1;
}
.button::before {
  content: "";
  position: absolute;
  background: #ff6a00;
  border: 50vh solid #ff9347;
  width: 30vh;
  height: 30vh;
  border-radius: 50%;
  display: block;
  top: 50%;
  left: 50%;
  z-index: 0;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.button:hover {
  color: $color-pr-orange;
  transition: transform 0.25s ease;
  transform: scale(1.02);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.button:active::before,
.button:focus::before {
  -webkit-transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
  transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
  transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
  transition: transform 1.12s ease, opacity 0.28s ease 0.364s,
    -webkit-transform 1.12s ease;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
}
.button:focus {
  outline: none;
}
.error {
  color: #ed4337;
  padding: 0;
  text-align: center;
}
.success {
  padding: 0;
  text-align: center;
  color: #f07924;
  font-weight: bold;
}

//<--Faculty-->
.card {
  margin: 5px;
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.2);
}
.card,
.card-img-top {
  border-radius: 0;
}
.card-img-top {
  height: 350px;
  object-fit: cover;
}
.name-title {
  font-weight: 800;
}
.faculty-grey {
  color: #b2b2b2ff;
  font-weight: 900;
}
.btnPrev {
  cursor: pointer;
  float: right;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/left-btn.svg");
  background-size: 40px;
}
.btnNext {
  cursor: pointer;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/right-btn.svg");
  background-size: 40px;
}
.faculty-container {
  max-width: 95vw;
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
  .logo {
    width: 200px;
  }
  .branding {
    margin-top: 20px;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    p {
      text-align: center !important;
    }
    .social-links {
      margin-left: auto;
      margin-right: auto;
    }
    .logo-footer {
      margin-right: auto;
      margin-left: auto;
      width: 75%;
      img {
        width: 100% !important;
      }
    }
  }
  .head-title {
    font-size: 2.5rem;
  }
  .content {
    margin-left: 30px;
    margin-right: 30px;
  }
  .news-letter {
    input {
      width: 100%;
    }
    button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  .menu {
    button {
      margin-right: 10px;
    }
  }
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .news-letter {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 900px) {
  .navbar-nav {
    height: 100vh;
    width: 100vw;
  }
  .show {
    display: block;
  }

  .navbar-collapse {
    top: 0px;
    position: fixed;
    width: 100vw;
    left: 0;
    height: 100vh;
    background-color: #ffff;
    .navbar-nav {
      top: 2%;
      text-align: center;
      position: absolute;
      .nav-item {
        padding: 3% 10%;
        a {
          font-size: 30px;
          letter-spacing: 4px;
          line-height: 45px;
          font-weight: bold;
        }
      }
    }
  }
}
/* Only for desktops*/
@media only screen and (min-width: 768px) {
  .branding {
    padding-top: 1rem;
  }
  .content {
    margin-left: 200px;
    margin-right: 200px;
  }

}
/* Only for large desktops more than 720p*/


@media screen and (min-width: 992px) {
  .branding {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .content {
    margin-left: 180px;
    margin-right: 180px;
  }
  .show {
    display: contents;
  }
  .collapse:not(.show){
    display: contents !important;
  }
  .navbar-nav {
    flex-direction: row;
  }
  .menu{
    display: none;
  }
}
@media (max-width: 768px) {
  .btnPrev,
  .btnNext {
    background-size: 20px;
  }
  .bose-img{
    img{
      border-radius: 40px;
      //width: 50% !important;
    }
  }

}
@media (min-width: 360px) {
  .art{
    background: linear-gradient(90deg, #f29d25 0%, #ef7824 61.74%);
    border: 0;
    outline: 0;
    color: #ffff;
    font-weight: bold;
    padding: 12px 17px;
    margin-left: 15px;
    margin-bottom: 120px;
    border-radius: 1px;
  }

}
@media (max-width: 385px) {
  .btnPrev,
  .btnNext {
    display: none;
  }
  .col-10 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

}

.art-section{

  min-height: 380px;
  width: 100%;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  p{
    line-height: 2;
    font-size: 18px;
  }

}
.event-rules li{
  line-height: 2;
}
@media (max-width: 465px) {
  .art{
    background: linear-gradient(90deg, #f29d25 0%, #ef7824 61.74%);
    border: 0;
    outline: 0;
    color: #ffff;
    font-weight: bold;
    padding: 12px 17px;
    margin-left: 55px;
    border-radius: 1px;
  }
  .art-section{
    height: auto;
  }
}
.sec-1{
  background: url("/static/images/art/bg1.jpg");
  p{

  }
}
.sec-2{
  background: url("/static/images/art/bg2.jpg");

}
.sec-3{
  background: url("/static/images/art/bg3.jpg");
}
.sec-4{
  background: linear-gradient(90deg, rgba(158,127,106,1) 36%, rgba(157,142,116,1) 74%);
  padding-bottom: 20px;
}
.lhight{
  line-height: 1px !important;
  margin-top: 35px;
}
.bose-img{
  padding: 20px;
  img{
    border-radius: 40px;
  }
}
hr{
  background: #fff;
}
.submit-btn{
  width: 200px;
  font-weight: 600;
  margin-bottom: 30px;
  background: #ef7826;
  border: none;
}
@media only screen and (min-width: 768px)
{
  .home-about{
    margin-left: 34px;
  }
  form{
    padding-left: 80px;
    padding-right: 80px;
  }
  .tagline{
    font-size: 11px;
    margin-left: 5rem;
    position: absolute;
    margin-top: -10px;
  }
  .preview-img{
    margin-top: -162px;
  }
  .home-slide-controls{

  }
}
.tagline{
  font-size: 11px;
}
.social-links{

}
.height-2{
  line-height: 2;
}
.sub-title{
  font-weight: 600;
  color: #f07924;
}
.link-btn{

  padding: 10px 12px;
  background: #ffff;
  color: #ef7824;
  text-decoration: none;
  font-weight: 600;
  display: block;
  width: 60%;
  margin: auto;
  margin-top: 20px;
}
.E-CLUB{
  margin-top: 44px !important;
}
.club-membership{
  font-size: 14px;
  min-height: 392px;
  border-color: #f07924;
  border-radius: 5px;
}
.club-membership .card-header{
  border-color: #f07924;
  border-bottom: solid 1px #f07924 !important;
}
.club-membership .card-title{
  font-size: 19px;
}
.certified p{
  line-height: 2;
}
.quote{

}
.pro-title{
  margin-bottom: -20px;
  margin-top: -48px;
}
.cource-card .vertical-center{
  text-align: justify !important;
}
@media only screen and (max-width: 468px){
  .art-section h1{
    font-size: 26px;
  }
  .sec-1{
    background: none;
  }
  .sec-2{
    background: none;
  }
  .sec-3{
    background: none;
  }
  .tagline{
    font-size: 11px;
    margin-left: 41px;
    margin-top: -5px;
    position: absolute;
    left: 30px;
  }
  .home-about h2{
    font-size: 1.5rem !important;
  }
  .home-slide-controls{
    margin-top: -18%;
  }
}
.home-about{
  padding: 20px;
  margin-top: 20px;
}

.full-screen{
  height: 100vh !important;
}
.controlls{

}
.carousel-item{
  width: 80%;
}
.quote h1{
  font-size: 1.5rem !important;
  height: auto !important;
  min-height: auto !important;
}