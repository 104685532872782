.timer {
	text-align: center;
	label {
		font-weight: 700;
		margin-left: 20px;
		margin-bottom: 0;
		vertical-align: middle;
		line-height: 30px;
		span {
			font-weight: 500;
			font-size: 0.8rem;
		}
	}
}
.end-test {
	text-align: right;
	.end-button {
		background: linear-gradient(90deg, #2c424f 0%, #2f383d 100%);
		width: 150px;
		display: flex;
		cursor: pointer;
		align-items: center;
		height: 38px;
		border-radius: 25px;
		float: right;
		label {
			font-size: 0.9rem;
			color: #ffff;
			width: 100%;
			cursor: pointer;
			text-align: center;
			font-weight: 700;
			vertical-align: middle;
			margin: 0;
			span {
				margin-left: 10px;
			}
		}
	}
}
.color-darken {
	background-color: #d5d5d5;
}
.chat-screen {
	top: 100px;
	left: 0;
	right: 0;
	bottom: 20px;
	position: absolute;
	width: auto;
	height: auto;
	.chat-area {
		position: absolute;
		top: 60px;
		bottom: 75px;
		overflow-y: auto;
	}
	.test-name {
		margin-top: 20px;
		text-align: center;
		font-weight: 700;
		color: #2f383d;
	}
	.input-area {
		position: absolute;
		bottom: 0;
		padding-bottom: 12px;
		input {
			width: 80%;
			background-color: #f4f5fc;
			border-radius: 25px;
			height: 50px;
			border: none;
			padding-left: 35px;
			color: #2f383d;
			font-weight: 700;
			&:disabled {
				cursor: not-allowed;
			}
		}
		.send-button {
			background: linear-gradient(90deg, #f29d26 0%, #ef7824 100%);
			width: 15%;
			display: flex;
			cursor: pointer;
			align-items: center;
			height: 50px;
			border-radius: 25px;
			float: right;
			margin-right: 30px;
			button {
				font-size: 0.9rem;
				color: #ffff;
				width: 100%;
				height: 100%;
				background: none;
				text-align: center;
				font-weight: 700;
				border: none;
				vertical-align: middle;
				margin: 0;
				&:disabled {
					cursor: not-allowed;
				}
				span {
					margin-left: 10px;
				}
			}
		}
	}
}
.chat-item {
	position: relative;
	max-width: 60%;
	margin-top: 20px;
	.chat-avatar {
		width: 30px;
		display: inline-block;
		position: absolute;
		bottom: 0;
	}
	.chat-content {
		display: inline-block;
		margin-left: 40px;
		font-size: 0.9rem;
		.question {
			background: #c0c0c0;
			border-radius: 15px;
			padding: 10px 20px;
		}
		.option {
			margin-top: 10px;
			background: #f4f5fc;
			border-radius: 15px;
			cursor: pointer;
			padding: 10px 20px;
			&:hover {
				background: #d9dbe4;
			}
		}
		.selected {
			background: #d9dbe4;
		}
	}
}
.answer {
	margin-right: 30px;
	text-align: end;
	.chat-content {
		margin-right: 15px;
	}
	.answer-content {
		background: #ef7a24;
		border-radius: 15px;
		padding: 10px 20px;
		color: #ffff;
	}
}
.typing-indicator {
	$ti-color-bg: #e6e7ed;
	background-color: $ti-color-bg;
	will-change: transform;
	width: auto;
	border-radius: 50px;
	padding: 10px;
	display: table;
	position: relative;
	animation: 2s bulge infinite ease-out;
	span {
		height: 8px;
		width: 8px;
		float: left;
		margin: 0 1px;
		background-color: #9e9ea1;
		display: block;
		border-radius: 50%;
		opacity: 0.4;
		@for $i from 1 through 3 {
			&:nth-of-type(#{$i}) {
				animation: 1s blink infinite ($i * 0.3333s);
			}
		}
	}
}

@keyframes blink {
	50% {
		opacity: 1;
	}
}

@keyframes bulge {
	50% {
		transform: scale(1.05);
	}
}

// vertically center demo
html {
	display: table;
	height: 100%;
	width: 100%;
}

body {
	display: table-cell;
	vertical-align: middle;
}

@media only screen and (max-width: 768px) {
	.navbar-brand {
		justify-content: center;
		display: -ms-flex; 
    display: flex;
	}
	.chat-screen {
		.chat-area {
			width: 92%;
			padding: 0;
		}
		.input-area {
			padding: 0;
			width: 90%;
			input {
				width: 70%;
				height: 45px;
			}
			.send-button {
				width: 25%;
				height: 45px;
				margin-right: 0;
			}
		}
	}
	.timer {
		width: 60%;
		img {
			width: 25px;
		}
		label {
			margin-left: 15px;
		}
	}
	.end-test {
		width: auto;
		padding-right: 0;
		padding-left: 0;
		.end-button {
			width: 130px;
			label {
				font-size: 0.7rem;
			}
		}
	}
	.navbar-brand {
		text-align: center;
	}
	.chat-item{
		max-width: 80%;
	}
}
