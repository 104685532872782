.book-btn {
  width: 100%;
  height: 3rem;
  background: linear-gradient(90deg, #f29d25 0%, #ef7824 61.74%);
  color: white;
  font-weight: bolder;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: inline-block;
  width: 100%;
}
.book-opacity {
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.97) !important;
}
.text-div {
  p {
    color: #31373b;
  }
}
.smile-booking {
  font-size: 250px;
  color: #1eff00da;
}
.booking-detail {
  color: #1eff00da;
  font-weight: bold;
  font-size: 40px;
}
@media screen and (min-width: 800px) {
  .mar-top {
    margin-top: 15vh;
  }
}
@media screen and (max-width: 800px) {
  .text-div {
    text-align: center;
  }
}
