$grey: #b2b2b2;
.img-coursedetail {
  height: 350px;
  width: 100%;
  object-fit: cover;
}
.cnt-coursedetail {
  padding: 0 !important;
}
.coursedetail-t4 {
  margin-top: 30px;
  color: $grey;
}
.links a:first-child {
  border-left: 0;
}
.links a {
  margin-bottom: 3rem;
  font-size: 22px;
  padding: 4px 12px;
  display: inline-block;
  line-height: 26px;
  border-left: 2px solid #EF7824;
}
