.full-screen {
	width: 100vw;
	height: 100vh;
	position: relative;
	background-size: cover;
	box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.9);
}
.align-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
}
.quote {
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin-left: 50px;
	h1 {
		min-height: 100px;
	}
	span {
		font-weight: bold;
	}
}
.home-buttons {
	display: inline-flex;
	padding: 2rem;
	.test-button {
		margin-top: 0px;
		a {
			text-decoration: none;
			color: black;
			padding: 8px 12px;
			border: 2px solid #ef7826;
			display: block;
			width: 220px;
			text-align: center;
			margin-top: 20px;
		}
	}
}
.preview-img {
	position: relative;
	height: 55vh;
	background-size: cover;
	img {
		width: auto;
		max-height: 400px;
		object-fit: cover;
	}
	.carousal-pos {
		position: absolute;
		bottom: 0;
		right: 0;
		padding-left: 30px;
		padding-right: 60px;
		padding-top: 10px;
		padding-bottom: 0;
		//box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.9);
		h1 {
			margin-bottom: 0;
			color: black;
			font-weight: bolder;
			span {
				vertical-align: text-top;
				color: #ef7826;
				font-size: 60%;
				font-weight: 500;
			}
		}
	}
}
.carousel-img {
	display: block !important;
	padding-right: 20px;
}
.social-links {
	display: flex;
	justify-content: space-between;
	margin-left: 25px;
	padding-right: 25px;
	a {
		color: #ef7826;
		&:hover {
			color: #b34700;
		}
	}
}
.bottom-margin {
	margin-bottom: 3%;
}
.prev-buttom {
	cursor: pointer;
	height: 50px;
	margin-right: 15px;
}
.next-buttom {
	cursor: pointer;
	height: 50px;
	margin-left: 15px;
}
.ribbon {
	background: linear-gradient(90deg, #f29d25 0%, #ef7824 61.74%);
	height: 40%;
	width: 25%;
	position: absolute;
	left: 3px;
	top: 35%;
}
.social-continer {
	.social-links {
		.svg-inline--fa {
			font-size: 20px;
		}
	}
}

@media only screen and (max-width: 900px) {
	.quote {
		h1 {
			text-align: center;
			font-size: 2rem;
		}
		span {
			font-weight: bold;
		}
	}
	.preview-img {
		height: 35vh;
	}
}

@media only screen and (max-width: 768px) {
	.ribbon {
		top: 25%;
		width: 33%;
		height: 55%;
	}
	.quote {
		margin-top: 10px;
		-ms-transform: none;
		transform: none;
		margin-left: 15px;
		margin-right: 15px;
		h1 {
			min-height: 13vh;
			font-size: 1.2rem;
			margin-bottom: 0;
			margin-top: 15px;
		}
	}
	.home-buttons {
		margin-top: 10px;
		.test-button {
			text-align: center;
			margin-top: 0;
			a {
				font-size: 0.8rem;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	.controlls {
		display: none;
	}
	.social-continer {
		height: 50px !important;
		.social-links {
			margin-left: auto !important;
			margin-right: auto !important;
			padding: 0px;
			.svg-inline--fa {
				font-size: 22px;
			}
		}
	}
}

@media only screen and (min-width: 1920px) {
	.quote {
		min-height: 200px;
		h1 {
			font-size: 3.5rem;
		}
	}
	.social-continer {
		.social-links {
			.svg-inline--fa {
				font-size: 30px;
			}
		}
	}
}
