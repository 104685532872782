@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}
.error-page {
  position: absolute;
  display: block;
}
.oops {
  margin-top: 2rem;
  font-weight: 1000;
  font-size: 3.5rem;
}
.msg {
  margin-top: 1rem;
  font-weight: 300;
  font-size: 2rem;
}
.nf-image {
  background-image: url("../images/error404.svg");
  height: 50vh;
  background-size: 40vw;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: bottom;
}
.nf-text {
  height: 33vh;
  color: #000;
}
.error-image {
  background-image: url("../images/error.svg");
  height: 85vh;
  background-position: center;
  background-repeat: no-repeat;
}
.nf-link {
  &:hover {
    text-decoration: none;
  }
}
