.cource-card {
  background: linear-gradient(90deg, #f29d25 0%, #ef7824 61.74%);
  border:solid 1px #f29d25;
  border-radius: 3px;
  color: #ffff;
  margin-bottom: 30px;
  height: 100%;
  min-height: 400px;
  .cource-img {
    img {
      width: 100%;
    }
  }
  .details {
    height: 100%;
    margin-bottom: 20px;
    .read-more {
      margin-top: 10px;
      padding: 10px 12px;
      background: #ffff;
      color: #ef7824;
      text-decoration: none;
      font-weight: 600;
    }
    .home-buttons .test-button a{
      margin-top: 20px;
    }
    h4 {
      margin-top: 10px;
      font-weight: bold;
    }
    .vertical-center {
      .level-head {
        color: #b05a20;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 7px;
      }
      .level-alt {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 70px;
      }
      .levels {
        list-style: none;
        padding-left: 0;
        font-weight: 700;
      }
    }
  }

  &:hover {
    background: #ffff;
    color: black;
    transition: transform 0.25s ease;
    transform: scale(1.02);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);

    .details {
      .vertical-center {
        .level-head {
          color: #b5b5b5;
        }
        .levels {
          color: #666666;
        }
      }
      .read-more {
        background: linear-gradient(90deg, #f29d25 0%, #ef7824 61.74%);
        color: white;
      }
    }
  }
}
