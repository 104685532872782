.img {
  min-height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 8px solid rgb(255, 255, 255);
  &:hover {
    transition: transform 0.25s ease;
    transform: scale(1.05);
  }
}
.a {
  background-image: url("../images/cources/star.jpg");
}
.lt {
  border-left: 0;
}
.rt {
  border-right: 0;
}
.bg-step {
  display: none;
}
.container-gallery {
  margin-left: 10rem;
  margin-right: 10rem;
}
.head-title-gallery {
  margin-bottom: 20px;
  font-size: 2.7rem;
  font-family: "Montserrat", sans-serif;
  color: black;
  font-weight: 500;
  span {
    color: #f07924;
    font-weight: 600;
  }
}
.container-gal {
  padding-right: 6px;
  margin-left: -8px;
  width: 100%;
  display: grid;
  grid-auto-flow: dense;
  // grid-template:
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh
  //   "1fr 1fr 1fr 1fr 1fr" 33.33vh;
}

.container-gal .box {
  position: relative;
  background: #ccc;
  &:hover {
    transition: transform 0.25s ease;
    transform: scale(1.05);
  }
}
.container-gal .box.a {
  grid-row: span 2;
}
.container-gal .box.b,
.container-gal .box.f,
.container-gal .box.h,
.container-gal .box.j {
  grid-column: span 2;
}
.container-gal .box img {
  border: 8px solid rgb(255, 255, 255);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 991px) {
  .container-gal {
    padding: 0;
    margin: auto;
    // grid-template:
    //   "1fr 1fr 1fr" 250px
    //   "1fr 1fr 1fr" 250px
    //   "1fr 1fr 1fr" 250px
    //   "1fr 1fr 1fr" 250px
    //   "1fr 1fr 1fr" 250px;
  }
  .maze {
    display: none;
  }
  .bg-step {
    display: block;
  }
}
@media (max-width: 768px) {
  .container-gallery {
    margin-left: unset;
    margin-right: unset;
  }
  // .container-gal {
  //   // grid-template:
  //   //   "1fr 1fr" 200px
  //   //   "1fr 1fr" 200px
  //   //   "1fr 1fr" 200px
  //   //   "1fr 1fr" 200px
  //   //   "1fr 1fr" 200px
  //   //   "1fr 1fr" 200px
  //   //   "1fr 1fr" 200px;
  // }
}
.container-gal .box.h {
  grid-column: span 1;
}
